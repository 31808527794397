import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

import Scroller from '../../components/Scroller/Scroller';

import styles from '../../styles/pages/example-pages.module.scss';

const CheckoutSuccessful: FC<RouteComponentProps> = () => (
  <Scroller>
    <article className={styles.content}>
      <h1>おめでとうございます!</h1>
      <p>これで購入は完了です。</p>
      <a href="/">店に戻します → </a>
    </article>
  </Scroller>
);

export default CheckoutSuccessful;
