import { ReactNode } from 'react';
import cx from 'classnames';

import styles from './Scroller.module.scss';

const Scroller = ({
  children,
  maxWidth,
  className,
}: {
  children: ReactNode;
  maxWidth?: number;
  className?: string | undefined;
}) => (
  <div className={cx(styles.scroller, className)}>
    <main className={styles.main} style={maxWidth ? { maxWidth } : {}}>
      {children}
    </main>
  </div>
);

export default Scroller;
